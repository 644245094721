<template>
  <div id="content">
    <div class="main-container bg-white text-left">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <h2 class="text-left"><router-link :to="{ name: 'home' }" class="text-body">{{ $t('company_platform') }}</router-link></h2>
            <ul class="p-0">
              <li><router-link :to="{ name: 'BecomeSeller' }" class="text-secondary">{{ $t('become_seller_button_join') }}</router-link></li>
              <li><router-link :to="{ name: 'WhatIsAnyTasks' }" class="text-secondary">{{ $t('what_is_anytask_link') }}</router-link></li>
              <li><router-link :to="{ name: 'Toolkit' }" class="text-secondary">{{ $t('toolkit_link') }}</router-link></li>
              <li><router-link :to="{ name: 'HowPaymentsWork' }" class="text-secondary">{{ $t('how_payment_work') }}</router-link></li>
              <li><router-link :to="{ name: 'anytask_protection' }" class="text-secondary">{{ $t('anytask_protection') }}</router-link></li>
              <li><router-link :to="{ name: 'terms_conditions' }" class="text-secondary">{{ $t('terms_of_service') }}</router-link></li>
              <li><router-link :to="{ name: 'privacy_policy' }" class="text-secondary">{{ $t('privacy_policy')}}</router-link></li>
              <li><router-link :to="{ name: 'intellectual_property' }" class="text-secondary">{{ $t('intellectual_property_claims') }}</router-link></li>
              <li><router-link :to="{ name: 'meet_the_sellers' }" class="text-secondary">{{ $t('meet_the_sellers_link')}}</router-link></li>
              <li><router-link :to="{ name: 'writing_your_brief' }" class="text-secondary">{{ $t('writing_your_brief.title') }}</router-link></li>
            </ul>
          </div>
          <div v-for="(category, i) in categories" :key="i" class="col-12 col-sm-6 col-md-4 col-lg-3">
            <h2 class="text-left"><router-link :to="{ name: 'category', params: { slug: category.slug } }" class="text-body">{{ category.name }}</router-link></h2>
            <ul class="p-0">
              <li v-for="(child, i) in category.child_categories" :key="i">
                <router-link v-if="!child.divider" :to="{ name: 'search_category', params: { slug: category.slug, child: child.slug.split('/')[1] } }" class="text-secondary">{{ child.name }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../apiService'

export default {
  name: 'Sitemap',
  data () {
    return {
      categories: null
    }
  },
  mounted () {
    ApiService.getAllCategories().then((r) => {
      this.categories = r.data.data
    }).catch(() => { })
  }
}
</script>
